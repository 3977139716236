import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { Tabs, List, Switch, Spin, Empty } from 'antd';

import styles from './index.module.scss';
import { useTitle, useWeixinConfig } from '../../hooks';
import {getWeixinProject, getWeixinMachine, ProjectItem, MachineItem, switchProjectOrMachine } from '../../api/alarmPushConfig';

const { TabPane } = Tabs;

const PushMgmt: FC = () => {
  useTitle('推送设置');

  const [jsApiList,] = useState<string[]>(["hideMenuItems", "hideAllNonBaseMenuItem"]);

  const readyCallback = useCallback(() => {
    window.wx.hideMenuItems({
      menuList: [
        "menuItem:share:appMessage",
        "menuItem:share:timeline",
        "menuItem:share:qq",
        "menuItem:share:weiboApp",
        "menuItem:favorite",
        "menuItem:share:facebook",
        "menuItem:share:QZone",
        "menuItem:editTag",
        "menuItem:delete",
        "menuItem:copyUrl",
        "menuItem:originPage",
        "menuItem:readMode",
        "menuItem:openWithQQBrowser",
        "menuItem:openWithSafari",
        "menuItem:share:email",
        "menuItem:share:brand",
      ]
    });
    window.wx.hideAllNonBaseMenuItem();
  }, [])

  useWeixinConfig(jsApiList, readyCallback);

  const [projectLoading, setProjectLoading] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<ProjectItem[]>([]);
  const [projectCount, setProjectCount] = useState<number>();

  const [machineLoading, setMachineLoading] = useState<boolean>(false);
  const [machineData, setMachineData] = useState<MachineItem[]>([]);
  const [machineCount, setMachineCount] = useState<number>();

  useEffect(() => {
    setProjectLoading(true);
    setMachineLoading(true);
    getWeixinProject({page: 0, per_page: -1}).then(res => {
      setProjectData(res.projectConfig)
      setProjectCount(res.total);
      setProjectLoading(false)
    });
    getWeixinMachine({page: 0, per_page: -1}).then(res => {
      setMachineData(res.machineConfig)
      setMachineCount(res.total);
      setMachineLoading(false)
    });;
  }, [])

  const projectCountStr = useMemo(() => {
    return projectCount !== undefined ? `(${projectCount})` : ''
  }, [projectCount])

  const machineCountStr = useMemo(() => {
    return machineCount !== undefined ? `(${machineCount})` : ''
  }, [machineCount])

  const onProjectSwitch = useCallback((id: number, closed: boolean) => {
    switchProjectOrMachine(id, closed).then(res => {
      const newData = [...projectData];
      const index = newData.findIndex(i => i.id === id);
      if (index !== -1) {
        newData.splice(index, 1, {
          ...newData[index],
          closed: closed
        })
        setProjectData(newData)
      }
    })
  }, [projectData])

  const onMachineSwitch = useCallback((id: number, closed: boolean) => {
    switchProjectOrMachine(id, closed).then(res => {
      const newData = [...machineData];
      const index = newData.findIndex(i => i.id === id);
      if (index !== -1) {
        newData.splice(index, 1, {
          ...newData[index],
          closed: closed
        })
        setMachineData(newData)
      }
    })
  }, [machineData])

  return (
    <div className={styles.container}>
      <Tabs defaultActiveKey="1" centered tabBarGutter={0} >
        <TabPane tab={`项目${projectCountStr}`} key="1">
          <Spin spinning={projectLoading}>
            {
              (!projectLoading && projectData.length) ? (
                <List
                  size="small"
                  header={<div className={styles.header}><span className={styles.icon}></span>已有推送项目</div>}
                  bordered
                  dataSource={projectData}
                  renderItem={(item: ProjectItem) => (
                    <List.Item actions={[
                      <div><Switch checked={!item.closed} onChange={(checked: boolean) => {
                        onProjectSwitch(item.id, !checked)
                      }}/></div>
                    ]}>
                      <List.Item.Meta
                        title={item.project_name}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Empty />
              )
            }
          </Spin>
        </TabPane>
        <TabPane tab={`设备${machineCountStr}`} key="2">
          <Spin spinning={machineLoading}>
            {
              (!machineLoading && machineData.length) ? (
                <List
                  size="small"
                  header={<div className={styles.header}><span className={styles.icon}></span>已有推送设备</div>}
                  bordered
                  dataSource={machineData}
                  renderItem={(item: MachineItem) => (
                    <List.Item actions={[
                      <div><Switch checked={!item.closed} onChange={(checked: boolean) => {
                        onMachineSwitch(item.id, !checked)
                      }}/></div>
                    ]}>
                      <List.Item.Meta
                        title={item.machine_name}
                        description={item.project_name}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Empty />
              )
            }
          </Spin>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default PushMgmt;
