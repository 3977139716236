import {fetch} from "@maxtropy/central-commons-ui";

export interface OssSecretKeyResponse {
  access_key_id: string;
  access_key_secret: string;
  bucket: string;
  region: string;
  security_token: string;
  update_time: string;
}

export function getOssSecretKey(): Promise<OssSecretKeyResponse> {
  return fetch(`/api/sts`);
}
