import { FC } from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import './App.scss';
import AlarmDetail from "./pages/AlarmDetail";
import PushMgmt from './pages/PushMgmt';

const App: FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/project/:projectId/live/alarm/:alarmId" exact>
            <AlarmDetail/>
          </Route>
          <Route path="/push-mgmt" exact>
            <PushMgmt/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
