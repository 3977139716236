import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { Row, Col, Carousel, Empty, Spin  } from 'antd';
import { RightOutlined } from '@ant-design/icons'
import classNames from 'classnames';
import moment from 'moment';
import qs from 'querystring';

import ReactEcharts from "../../components/ReactEcharts";

import MachineImage from '../../components/MachineImage';

import { useQuery, useTheme, useTitle, useWeixinConfig } from '../../hooks';

import styles from './index.module.scss';
import { Theme } from '../../hooks/useTheme';
import { getMachineStatus, MachineStatusResponse, getAlarmList, AlarmPoint, MachineType } from '../../api/wechatAlarm';
import useImageUrl from '../../components/MachineImage/useImageUrl';

type ChartData = Map<number, AlarmPoint[]>

const formatData = (data: AlarmPoint[]) => {
  const chartData: ChartData = new Map();
  data.reduce((pre, next) => {
    if (pre.get(next.key)) {
      const preData = pre.get(next.key)
      pre.set(next.key, [...preData!, next])
    } else {
      pre.set(next.key, [next])
    }
    return pre;
  }, chartData)
  return chartData;
}

type NameData = {
  key: number;
  name: string;
}

const formatName = (data: AlarmPoint[]) => {
  const nameData: {[key: number]: NameData} = {};
  data.forEach(i => {
    if (!data[i.key]) {
      nameData[i.key] = {
        key: i.key,
        name: i.name,
      }
    }
  })

  return Object.keys(nameData).map((name: string) => nameData[Number(name)]);
}

function chunkArray<T>(data: T[], size: number = 1) {
  if (!data) {
    return data;
  }
  if (data.length <= size) {
    return [data]
  }
  const chunkData = [];
  for (let i = 0; i < data.length; i+=size) {
    chunkData.push(data.slice(i, i+size))
  }
  return chunkData;
}

const patten = /^http/

const AlarmDetail: FC = () => {
  const query = useQuery();
  const { pathname } = useLocation();
  const { alarmId } = useParams<{projectId: string; alarmId: string}>();
  const [machineData, setMachineData] = useState<MachineStatusResponse>();
  const [chartData, setChartData] = useState<ChartData>();
  const [nameData, setNameData] = useState<NameData[][]>();
  const [empty, setEmpty] = useState<boolean>(false);
  const [jsApiList,] = useState<string[]>(["hideMenuItems", "updateAppMessageShareData", "hideAllNonBaseMenuItem"]);

  const [currentKey, setCurrentKey] = useState<number>();

  const [loading, setLoading] = useState<boolean>(false);

  const [memo, setMemo] = useState<MachineType>();
  const [url, setUrl] = useState<string>();

  const imageUrl = useImageUrl(url, memo);

  useTitle("报警详情");

  const theme = useTheme();

  const isShare = useMemo(() => {
    if (query) {
      return query.share && query.share === 'true';
    }
    return true;
  }, [query])

  useEffect(() => {
    setLoading(true);
    getMachineStatus(alarmId).then(res => {
      setMachineData(res)
      setMemo(res?.machine_type.memo)
      setUrl(res?.machine_picture)
    })
    getAlarmList(alarmId).then(res => {
      setChartData(formatData(res));
      const nameData = formatName(res);
      setLoading(false);
      if (nameData.length) {
        setNameData(chunkArray(nameData, 9));
        setCurrentKey(nameData[0].key);
      } else {
        setEmpty(true);
      }
      
    })
  }, [alarmId])

  const readyCallback = useCallback(() => {
    window.wx.hideMenuItems({
      menuList: [
        // "menuItem:share:appMessage",
        "menuItem:share:timeline",
        "menuItem:share:qq",
        "menuItem:share:weiboApp",
        "menuItem:favorite",
        "menuItem:share:facebook",
        "menuItem:share:QZone",
        "menuItem:editTag",
        "menuItem:delete",
        "menuItem:copyUrl",
        "menuItem:originPage",
        "menuItem:readMode",
        "menuItem:openWithQQBrowser",
        "menuItem:openWithSafari",
        "menuItem:share:email",
        "menuItem:share:brand",
      ]
    });
  }, [])

  const wxStatus = useWeixinConfig(jsApiList, readyCallback);

  useEffect(() => {
    if (wxStatus && machineData && imageUrl) {
      window.wx.updateAppMessageShareData({ 
        title: 'DbPE动力设备智能服务云平台', // 分享标题
        desc: `【${machineData?.project_name}】项目的【${machineData?.machine_name}】设备关于【${machineData?.alarm_rule}】的报警信息`, // 分享描述
        link: `${window.location.origin}${pathname}?${qs.stringify({
          ...query,
          share: 'true'
        })}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: patten.test(imageUrl) ? imageUrl : `${window.location.origin}${imageUrl}`, // 分享图标
        success: function () {
          // 设置成功
          // console.log('设置分享成功')
        }
      })
    }
  }, [wxStatus, imageUrl, machineData, query, pathname])

  

  const currentCharData = useMemo(() => {
    if (currentKey && chartData) {
      return chartData?.get(currentKey)
    }
  }, [currentKey, chartData])

  const options = useMemo(() => {
    if (currentCharData) {
      const xAxis = currentCharData?.map(i => moment(i.timestamp).format('mm:ss'))
      const values = currentCharData?.map(i => Number(i.value.toFixed(2)))
      return {
        grid: {
          top: "12%",
          bottom: "12%",
        },
        backgroundColor: theme === Theme.DARK ? '#1a1819' : undefined,
        xAxis: {
            type: 'category',
            data: xAxis
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: values,
            type: 'line'
        }]
      }
    }
    return undefined;
  }, [currentCharData, theme])

  const buttonClick = (key: number) => {
    if (key !== currentKey) {
      setCurrentKey(key);
    }
  }

  return (
    <div className={styles.container}>
      <Row className={styles.desc}>
        <Col span={10} className={styles.image}>
          <MachineImage memo={machineData?.machine_type.memo} image={machineData?.machine_picture}/>
        </Col>
        <Col span={14} className={styles.content}>
          <div className={styles.item}>所属项目：{machineData?.project_name}</div>
          <div className={styles.item}>设备名称：{machineData?.machine_name}</div>
          <div className={styles.item}>报警规则：{machineData?.alarm_rule}</div>
          <div className={styles.item}>报警时间：{machineData?.alarm_time ? moment(machineData?.alarm_time).format("YYYY-MM-DD hh:mm:ss") : '--'}</div>
        </Col>
      </Row>
      {
        empty ? (
          <Row className={classNames(styles.row, styles.space)}>
            <Col span={24}>
              <Empty />
            </Col>
          </Row>
        ) : (
          <Spin spinning={loading}>
            <Row className={classNames(styles.row, styles.space)}>
              <Col span={24}>
                <div >
                  <ReactEcharts className={styles.charts}
                    option={options}
                    theme={theme}
                  />
                </div>
              </Col>
            </Row>
            <Row className={classNames(styles.row, styles.space)}>
              <Col span={24}>
                <Carousel draggable={true} dots={{className: styles.dots}}>
                    {
                      nameData && nameData.map((names, index) => {
                        return (
                          <div className={styles.carousel} key={index}>
                            <Row className={styles.buttonRow}>
                              {
                                names.map(i => {
                                  return (
                                    <Col span={8} className={styles.buttonCol} key={i.key}>
                                      <div className={classNames(styles.button, {[styles.active]: i.key === currentKey})} onClick={() => {buttonClick(i.key)}}>{i.name}</div>
                                    </Col>
                                  )
                                })
                              }
                            </Row>
                          </div>
                        )
                      })
                    }
                  </Carousel>
              </Col>
            </Row>
          </Spin>
        )
      }
      {
        isShare ?? (
          <Row className={classNames(styles.row, styles.space)}>
            <Col span={24}>
              <Link to={"/push-mgmt"}>
                <div className={styles.cell}>
                  <div>推送设置</div>
                  <div className={styles.right}><RightOutlined /></div>
                </div>
              </Link>
            </Col>
          </Row>
        )
      }
    </div>
  )
}

export default AlarmDetail;