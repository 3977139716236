import {fetch} from "@maxtropy/central-commons-ui";

interface WeixinSignatureResponse {
  signature: string;
  noncestr: string;
  app_id: string;
  timestamp: string;
}

export function getWeixinSignature(url: string, app_name: string): Promise<WeixinSignatureResponse> {
  return fetch(`/api/weixin/signature?url=${encodeURIComponent(url)}&app_name=${app_name}`)
}