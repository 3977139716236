import { useEffect, useState } from "react";
import { MachineType } from "../../api/wechatAlarm";

import { signUrlAsync } from "../../services/oss";

import compressor from '../../assets/images/machine-compressor.png';
import dryer from '../../assets/images/machine-dryer.png';
import meter from '../../assets/images/machine-meter.png';
import flow from '../../assets/images/machine-flow.png';
import other from '../../assets/images/machine-other.png';

function displayImg(machineType: MachineType | undefined) {
  switch (machineType) {
    case MachineType.COMPRESSOR:
      return compressor;
    case MachineType.DRYER:
      return dryer;
    case MachineType.FLOW:
      return flow;
    case MachineType.METER:
      return meter;
    case MachineType.OTHER:
    default:
      return other;
  }
}

const useImageUrl = (image?: string, memo?: string) => {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    console.log('refresh', image, memo)
    // 优先展示编辑设备中的图片
    if (image) {
      signUrlAsync(image).then((url) => {
        setImageUrl(url);
      });
      return;
    } else {
      setImageUrl(displayImg(memo as MachineType));
    }
  }, [image, memo]);

  return imageUrl;
}

export default useImageUrl;