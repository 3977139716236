import { useState, useEffect } from 'react';

import { getWeixinSignature } from '../api/weixin'

const useWeixinConfig = (apiList: string[], readyCallback: () => void) => {
  const [status, setStatus] = useState<boolean>(false);
  useEffect(() => {
    getWeixinSignature(window.location.href.split('#')[0], 'service_account_main').then(res => {
      window.wx.config({
        // debug: true,
        timestamp: res.timestamp,
        appId: res.app_id,
        nonceStr: res.noncestr,
        signature: res.signature,
        jsApiList: apiList // 必填，需要使用的JS接口列表
      });
    })
    
  }, [apiList])

  useEffect(() => {
    window.wx.ready(function(){
      console.log('ready')
      setStatus(true);
      readyCallback();
    });
    window.wx.error(function(res: any){
      console.log(res)
    });
  }, [])

  return status;
}

export default useWeixinConfig;