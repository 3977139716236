import { useEffect, useRef } from 'react';

const useTitle = (title: string) => {
  const titleRef = useRef<string>(document.title);
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = titleRef.current;
    }
  }, [title])
}

export default typeof document !== 'undefined' ? useTitle : () => {};