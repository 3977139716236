import { Key } from 'react'
import {fetch} from "@maxtropy/central-commons-ui";

export enum MachineType {
  COMPRESSOR = 'AIR_COMPRESSOR_TYPE', //空压机
  METER = 'VOLTMETER_TYPE',//智能电表
  FLOW = 'FLOW_TYPE',//流量计
  DRYER = 'Dryer_TYPE',//干燥机
  OTHER = 'OTHER_TYPE',//其他设备
}

export interface MachineStatusResponse {
  alarm_rule: string;
  alarm_time: number;
  machine_name: string;
  machine_picture?: string;
  project_name: string;
  machine_type: { name: string; memo: MachineType; id: number };
}

export function getMachineStatus(alarmId: Key): Promise<MachineStatusResponse> {
  return fetch(`/api/wechat-alarm/machine/status?alarmId=${alarmId}`)
}

export interface AlarmPoint {
  key: number;
  name: string;
  timestamp: string;
  unit?: string;
  value: number;
}

export function getAlarmList (alarmId: Key): Promise<AlarmPoint[]> {
  return fetch(`/api/wechat-alarm/list/${alarmId}`)
} 