import { useState, useEffect } from 'react';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark'
}

const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

const theme = mediaQuery.matches ? Theme.DARK : Theme.LIGHT;

const useTheme = () => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(theme);

  function darkModeHandler() {
    if (mediaQuery.matches) {
      setCurrentTheme(Theme.DARK)
    } else {
      setCurrentTheme(Theme.LIGHT)
    }
  }

  useEffect(() => {
    mediaQuery.addEventListener && mediaQuery.addEventListener("change", darkModeHandler);
    return () => {
      mediaQuery.removeEventListener && mediaQuery.removeEventListener("change", darkModeHandler);
    }
  }, [])

  return currentTheme;
}

export default useTheme;