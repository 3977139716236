import { FC } from 'react';

import * as echarts from "echarts/core";

import ReactEchartsCore, { ReactEchartsCoreProps } from './core';

import {
  GridComponent
} from 'echarts/components';
import {
  LineChart
} from 'echarts/charts';
import {
  CanvasRenderer
} from 'echarts/renderers';

echarts.use(
  [GridComponent, LineChart, CanvasRenderer]
);


interface ReactEchartsProps extends Omit<ReactEchartsCoreProps, 'echartsLib'> {}

const ReactEcharts: FC<ReactEchartsProps> = (props) => {
  return (
    <ReactEchartsCore echartsLib={echarts} {...props} />
  )
}

export default ReactEcharts;