import { CSSProperties, FC, useEffect, useRef } from 'react';
import * as echarts from "echarts/core";
import {bind} from 'size-sensor';

import { EChartsCoreOption } from 'echarts/core'
import { Theme } from '../../hooks/useTheme';

export interface ReactEchartsCoreProps {
  echartsLib: typeof echarts;
  className?: string;
  style?: CSSProperties;
  theme?: Theme | object;
  opt?: any;
  option?: EChartsCoreOption;
  notMerge?: boolean;
  lazyUpdate?: boolean;
  loadingOption?: object;
  showLoading?: boolean;
}

const ReactEchartsCore: FC<ReactEchartsCoreProps> = (props) => {
  const { echartsLib, className, style, theme, opt, option } = props;

  const divRef = useRef<HTMLDivElement>(null);

  const resize = () => {
    const instance = getEchartsInstance();
    instance.resize();
  }

  useEffect(() => {
    if (divRef.current) {
      rerender();
      const unbind = bind(divRef.current, resize)
      return () => {
        unbind();
      }
    }
  }, [])

  useEffect(() => {
    dispose();
    rerender();
  }, [theme, opt])

  useEffect(() => {
    rerender();
  }, [option])

  const getEchartsInstance = () => {
    return echartsLib.getInstanceByDom(divRef.current!) || echartsLib.init(divRef.current!, theme, opt);
  }

  const rerender = () => {
    // const { onEvents, onChartReady } = props;

    renderEchartDom();
    // this.bindEvents(echartObj, onEvents || {});

    // on chart ready
    // if (typeof onChartReady === 'function') this.props.onChartReady(echartObj);
    // on resize
  };

  const renderEchartDom = () => {
    const {option = {}, notMerge=false, lazyUpdate=false, loadingOption, showLoading} = props;
    // init the echart object
    const echartObj = getEchartsInstance();
    // console.log(echartObj, option);
    // set the echart option
    echartObj.setOption(option, notMerge, lazyUpdate);
    // set loading mask
    if (showLoading) echartObj.showLoading(loadingOption);
    else echartObj.hideLoading();

    return echartObj;
  };

  const dispose = () => {
    echarts.dispose(divRef.current!);
  }

  return (
    <div 
      ref={divRef}
      className={className}
      style={style}
    />
  )
}

export default ReactEchartsCore;