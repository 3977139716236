import { getOssSecretKey, OssSecretKeyResponse } from "../api/oss";

interface WindowProps extends Window {
  OSS: any;
}

declare const window: WindowProps;

function ossClient(secret: OssSecretKeyResponse): any {
  const {
    region,
    access_key_id,
    access_key_secret,
    bucket,
    security_token,
  } = secret;
  return new window.OSS.Wrapper({
    region,
    accessKeyId: access_key_id,
    accessKeySecret: access_key_secret,
    bucket,
    stsToken: security_token,
  });
}

class OSSSecretKeyHolder {
  _secret: OssSecretKeyResponse | undefined = undefined;
  private _readyCallback: (() => void) | undefined;
  private readonly _readyPromise: Promise<OssSecretKeyResponse | undefined>;

  constructor() {
    this._readyPromise = new Promise((resolve) => {
      this._readyCallback = () => resolve(undefined);
    });
    this.doFetchSecret();
    setInterval(this.doFetchSecret, 300000);
  }

  doFetchSecret = async () => {
    try {
      this._secret = await getOssSecretKey();
      if (this._readyCallback) {
        this._readyCallback();
        this._readyCallback = undefined;
      }
    } catch (e) {
      console.error(e);
    }
  };

  get secret() {
    return this._secret;
  }

  get readyPromise() {
    return this._readyPromise;
  }
}

export function signedURL(
  key: string,
  secret: OssSecretKeyResponse,
  options: { process?: string; response?: any }
): any {
  if (!key || !secret.access_key_id) {
    return "";
  }
  const [realKey, x, y, width, height] = key.split(",");
  if (x && y && width && height) {
    const cropParam = `image/crop,x_${x},y_${y},w_${width},h_${height}`;
    const suffix =
      options && options.process
        ? options.process.split("/").slice(1).join("/")
        : "";
    const process = `${cropParam}/${suffix}`;
    return signedURL(realKey, secret, { ...options, process });
  }
  return ossClient(secret).signatureUrl(key, options);
}

export const ossSecretKeyHolder = new OSSSecretKeyHolder();

export async function signUrlAsync(key: string, options = {}) {
  await ossSecretKeyHolder.readyPromise;
  return signedURL(key, ossSecretKeyHolder.secret!, options);
}
