import { useLocation } from 'react-router-dom';
import qs from 'querystring';
import { useEffect, useState } from 'react';

const useQuery = () => {
  const [query, setQuery] = useState<qs.ParsedUrlQuery>();
  const location = useLocation();
  const { search } = location;
  useEffect(() => {
    setQuery(qs.parse(search.replace('?', '')))
  }, [search])
  return query;
}

export default useQuery;