import {fetch} from "@maxtropy/central-commons-ui";
import qs from 'qs';

import { PageRequest, PageResponse } from './entity';


export interface ProjectItem {
  project_name: string;
  id: number;
  closed: boolean;
}

export interface ProjectResponse extends PageResponse {
  projectConfig: ProjectItem[];
}

export function getWeixinProject(query: PageRequest): Promise<ProjectResponse> {
  return fetch(`/api/alarm-push-config/h5/project?${qs.stringify(query, {indices: false})}`)
}

export interface MachineItem extends ProjectItem {
  machine_name: string;
}

export interface MachineResponse extends PageResponse {
  machineConfig: MachineItem[];
}

export function getWeixinMachine(query: PageRequest): Promise<MachineResponse> {
  return fetch(`/api/alarm-push-config/h5/machine?${qs.stringify(query, {indices: false})}`)
}

export function switchProjectOrMachine(id: number, closed: boolean): Promise<void> {
  return fetch(`/api/alarm-push-config/h5/status/${id}?status=${closed}`, {
    method: "PUT"
  })
}