import styles from "./index.module.scss";
import useImageUrl from './useImageUrl';

const MachineImage = ({
  image,
  memo,
}: {
  image?: string;
  memo?: string;
}) => {
  const imageUrl = useImageUrl(image, memo);

  return imageUrl ? (
    <img src={imageUrl} alt={"machine"} className={styles.machineImg} />
  ) : (
    <div className={styles.machineImg} />
  );
};

export default MachineImage;
